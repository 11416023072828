// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-harfen-fiona-tsx": () => import("./../../../src/pages/harfen/fiona.tsx" /* webpackChunkName: "component---src-pages-harfen-fiona-tsx" */),
  "component---src-pages-harfen-index-tsx": () => import("./../../../src/pages/harfen/index.tsx" /* webpackChunkName: "component---src-pages-harfen-index-tsx" */),
  "component---src-pages-harfen-isabeau-tsx": () => import("./../../../src/pages/harfen/isabeau.tsx" /* webpackChunkName: "component---src-pages-harfen-isabeau-tsx" */),
  "component---src-pages-harfen-wyda-tsx": () => import("./../../../src/pages/harfen/wyda.tsx" /* webpackChunkName: "component---src-pages-harfen-wyda-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leihharfen-tsx": () => import("./../../../src/pages/leihharfen.tsx" /* webpackChunkName: "component---src-pages-leihharfen-tsx" */),
  "component---src-pages-vita-tsx": () => import("./../../../src/pages/vita.tsx" /* webpackChunkName: "component---src-pages-vita-tsx" */),
  "component---src-pages-werkstatt-tsx": () => import("./../../../src/pages/werkstatt.tsx" /* webpackChunkName: "component---src-pages-werkstatt-tsx" */),
  "component---src-pages-zubehoer-tsx": () => import("./../../../src/pages/zubehoer.tsx" /* webpackChunkName: "component---src-pages-zubehoer-tsx" */)
}

